// @flow

export const appEnvironment = 'pre-production';

export const API_URL = 'https://ms-api-dot-pprd-minchem-solvextract.appspot.com';
export const API_VER = '/api/';

export const firebaseConfig = {
    apiKey: 'AIzaSyBDcrIHFtBV2cP_J8GWgwuvPvuuy7ZoHUM',
    authDomain: 'pprd-minchem-solvextract.firebaseapp.com',
    projectId: 'pprd-minchem-solvextract',
};

export const gaTrackingId = '';

// Used to replace Login UI, for application blocking (must contain content to block application)
export const appBlockingMessage = '';

export const NAVBAR_DASHBOARD_V2_REDIRECT = true;

/**
 * A list of users allowed to use MinChem V2
 * The list should be an array of emails.
 */
export const MINCHEM_V2_TEST_USER_EMAILS = [];

export const PIWIK_PRO_TRACKERID = '';
export const PIWIK_PRO_API_URL = '';